

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home, .reports, .tool {
  display: felx;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
