.tool-form {
    padding: 10px;
}

#how {
    padding-top: 5px;
}

#forklaring {
    padding-bottom: 5px;
}