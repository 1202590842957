.masthead {
    padding-top: calc(6rem + 74px);
    padding-bottom: 6rem;
  }
  .masthead .masthead-heading {
    font-size: 2.75rem;
    line-height: 2.75rem;
    padding-bottom: 3rem;
  }
  .masthead .masthead-subheading {
    font-size: 1.25rem;
  }
  .masthead .masthead-avatar {
    width: 15rem;
  }
  
  @media (min-width: 992px) {
    .masthead {
      padding-top: calc(6rem + 104px);
      padding-bottom: 6rem;
    }
    .masthead .masthead-heading {
      font-size: 4rem;
      line-height: 3.5rem;
    }
    .masthead .masthead-subheading {
      font-size: 1.5rem;
    }
  }